<div class="container-fluid invite-user-form-container">
	<form [formGroup]="inviteForm">
		<div class="form-row">
			<div *ngIf="userType.value === 'customer' || this.role !=='SGRE Admin'" class=" col-lg-6">
				<label class="request-label required">
					{{"inviteUser.firstName" |cxTranslate}}
				</label>
				<input type="text" class="form-control request-data inp-element" formControlName="firstName" width="85%"
					[ngClass]="{'formControlError':(firstNameError)}">
				<span *ngIf="firstNameError" style="color:red">
					{{"inviteUser.invalidFirstName" |cxTranslate}}
				</span>
			</div>
		</div>
		<div class="form-row">
			<div *ngIf="userType.value === 'customer' || this.role !=='SGRE Admin'" class="form-group col-lg-6 mt-3">
				<label class="request-label required">
					{{"inviteUser.lastName" |cxTranslate}}
				</label>
				<input type="text" class="form-control request-data inp-element"
					[ngClass]="{'formControlError':(lastNameError)}" formControlName="lastName">
				<span *ngIf="lastNameError" style="color:red">
					{{"inviteUser.invalidLastName" |cxTranslate}}
				</span>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-lg-6">
				<label class="request-label required">
					{{"inviteUser.email" |cxTranslate}}
				</label>
				<input type="text" class="form-control request-data inp-element"
					[ngClass]="{'formControlError':(emailError || emailErrormsg)}" formControlName="email">
				<span *ngIf="emailError || emailErrormsg" style="color:red">
					{{emailErrormsg}}
					<!-- {{(emailErrormsg ? emailErrormsg : "inviteUser.invalidEmail")|cxTranslate}} -->
				</span>
			</div>
		</div>
		<div *ngIf="role == 'SGRE Admin'" class="form-row">
			<div class="form-group col-lg-6">
				<label class="request-label required">
					{{"inviteUser.userType" |cxTranslate}}
				</label>
				<div class="option-container">
					<input (click)="clearTable();" type="radio" value="SGRE" formControlName="userType" class="custom-radio">
					<span>{{"inviteUser.sgreUser" |cxTranslate}}</span>
				</div>
				<div class="option-container">
					<input (change)="userTypeChange('customer')" (click)="clearTable();" type="radio" value="customer" class="custom-radio" 
						formControlName="userType">
					<span>{{"inviteUser.customerUser" |cxTranslate}}</span>
				</div>
			</div>
		</div>
		<div *ngIf="userType.value !== 'customer' && this.role == 'SGRE Admin'" class="form-row">
			<div class="form-group col-lg-6">
				<label class="request-label required">
					{{"inviteUser.userRole" |cxTranslate}}
				</label>
				<div class="option-container">
					<input type="radio" value="sales" formControlName="userRole" class="custom-radio">
					<span>{{"inviteUser.salesRepresentative" |cxTranslate}}</span>
				</div>
				<div class="option-container">
					<input type="radio" value="administrator" formControlName="userRole" class="custom-radio">
					<span>{{"inviteUser.administrator" |cxTranslate}}</span>
				</div>
			</div>
		</div>
		<div *ngIf="userType.value === 'customer' && this.role == 'SGRE Admin'" class="form-group company-cls col-lg-6">
			<label class="request-label required">
				{{'inviteUser.company' | cxTranslate}}
			</label>
			<div>
				<p-dropdown [options]="companyData" [filter]="true"
					[styleClass]="companyError ? 'border-red-500' : 'border-round-md'"
					(onChange)="changeCompany($event)" optionLabel="name" optionValue="name" filterBy="name"
					class="dropdon-cls" placeholder="{{'inviteUser.selectACompany' | cxTranslate}}"
					formControlName="company" required>
				</p-dropdown>
			</div>
			<span *ngIf="companyError" style="color:red">
				{{"inviteUser.invalidCompany" |cxTranslate}}
			</span>
		</div>
		<div *ngIf="this.role !=='SGRE Admin'" class="form-row">
			<div class="form-group col-lg-6">
				<label class="request-label required">
					{{"inviteUser.company" |cxTranslate}}
				</label>
				<div class="option-container">
					<input (click)="radioValue('myCompany')" type="radio" value="myCompany" name="companyExt" class="custom-radio" 
						formControlName="companyExt" checked>
					<span>{{"inviteUser.UserIsEmployed" |cxTranslate}}</span>
				</div>
				<div class="option-container">
					<input (click)="radioValue('companyExt')" type="radio" value="anotherCom" name="companyExt" class="custom-radio" 
						npformControlName="companyExt">
					<span>{{"inviteUser.UserIsEmployedAnotherCompany" |cxTranslate}}</span>
				</div>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-lg-12">
				<div *ngIf=" userType.value === 'SGRE' && inviteForm.get('userRole').value == 'sales' && this.role == 'SGRE Admin'"
					class="inviteUser-table-container">
					<label class="request-label required">
						{{"inviteUser.roles" |cxTranslate}}
					</label>
					<label *ngIf="arrayFlag1" style="color: red;">
						{{"inviteUser.leastRightsToOneLegalEntity" |cxTranslate}}
					</label>
					<app-table-template [columns]="columns" [tableData]="cartsList"
						(checkBtnClickEvent)="removeRow($event)">
					</app-table-template>
				</div>
				<div *ngIf="userType.value === 'customer' || this.role !=='SGRE Admin'"
					class="inviteUser-table-container">
					<label class="request-label required">
						{{"inviteUser.roles" |cxTranslate}}
					</label>
					<label *ngIf="arrayFlag2" style="color: red;">
						{{"inviteUser.leastRightsToOneLegalEntity" |cxTranslate}}
					</label>
					<app-table-template [columns]="column1" [tableData]="cartsList"
						(checkBtnClickEvent)="checkEvent($event)">
					</app-table-template>
				</div>
			</div>
		</div>
		<div class="form-row mb-4 px-0">
			<div *ngIf="userType.value==='customer'||userType.value==='SGRE' && inviteForm.get('userRole').value==='sales'"
				class="form-group col-lg-12">
				<div class="col-lg-6 d-flex px-0 legal-entity-container align-items-center" style="height: fit-content;">
					<p-dropdown styleClass="cstm-dropdown" [options]="legalentities" [filter]="true" optionLabel="name"
						filterBy="name" optionValue="uid" class="dropdon-cls" panelStyleClass="minWidthOverride"
						placeholder="{{'inviteUser.selectALegalEntity' |cxTranslate}}" formControlName="addValues">
					</p-dropdown>
					<button (click)="addEntity()" type="button" class="btn cstm-btn-primary ml-2 addBtn pb-1" label="Add">
						{{"inviteUser.add" |cxTranslate}}
					</button>
				</div>
			</div>
		</div>
		<div class="form-row mb-3">
			<div class="form-group">
				<button (click)="submitInviteUser()" type="submit" class="btn cstm-btn-primary mb-2"
					style="margin-left: 7px;">
					{{"inviteUser.inviteUser" |cxTranslate}}
				</button>
			</div>
		</div>
	</form>
</div>

<!-- Modal -->
<div [ngStyle]={display:this.display} class="modal" id="exampleModal" tabindex="-1" role="dialog" data-backdrop="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title">
					{{"inviteUser.confirmation" |cxTranslate}}
				</h1>
				<button (click)="onCloseHandled()" type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" style="overflow:hidden;">
				<div *ngIf="isSuccess==2" class="custom-align-center">
					<div class="mr-2">
						<i class="pi pi-exclamation-circle confirmation-icon failure-icon"></i>
						<!-- <img src="../../../../../assets/images_and_icons/mark.png" style="width: 25px;height: 25px;"> -->
					</div>
					<div>
						{{this.emailErrormsg}}
						<!-- {{(emailErrormsg ? emailErrormsg : "inviteUser.invalidEmail") |cxTranslate}} -->
					</div>
				</div>
				<div *ngIf="isSuccess==1" class="custom-align-center">
					<div class="mr-2">
						<i class="pi pi-check-circle confirmation-icon success-icon"></i>
						<!-- <img src="../../../../../assets/images_and_icons/check.png" style="width: 25px;height: 25px;"> -->
					</div>
					<div>{{"inviteUser.invitationSent" |cxTranslate}}</div>
				</div>
			</div>
			<div class="modal-footer justify-content-start">
				<button (click)="onCloseHandled()" type="button" class="btn cstm-btn-primary" data-dismiss="modal">
					{{"inviteUser.ok" |cxTranslate}}
				</button>
			</div>
		</div>
	</div>
</div>